export const policyPoints = [
  "Compliance with Company Policy: Your use of the chatbot is governed by company policies, including but not limited to the Employee Code of Conduct, Data Protection Policy, and IT Security Guidelines.",
  "Purpose of Use: The chatbot is intended for internal use only to assist employees with work-related queries, information, and tasks. It is not to be used for personal purposes or any activity that contravenes company policies.",
  "User Responsibility: You are responsible for ensuring that all information provided and received through the chatbot is used appropriately and in compliance with internal policies and applicable laws.",
  "Consent to Monitoring: We may collect and process data related to your use of the chatbot for operational, support, and improvement purposes, subject to the terms outlined in the company's Privacy Policy.",
  "Data Accuracy: While the chatbot aims to provide accurate and timely information, it is not infallible. Employees are responsible for verifying critical information before acting on it.",
  "Users should refrain from sharing any personal, sensitive, or confidential information (such as names, contact details, financial data, or any other personal identifiers) with the chatbot. Sharing such details may compromise user privacy and violate the terms of service",
];

export const messages = {
  userAgreementFull:
    "HDFC ERGO has given you the ability to use AMA- your own chatbot.This is a user agreement between HDFC ERGO and the employee you your regarding the use of this internal chatbot system.",
  userAgreementTruncated:
    "This is a user agreement between HDFC ERGO and the employee you, your regarding the use of this internal chatbot system. By clicking Agree and continuing to use this service, you acknowledge and agree to the following:",
  userAcknowledgment:
    "By clicking Agree, you confirm that you have read, understood, and accepted the terms outlined in this agreement.",
};
