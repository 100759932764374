import { Box, useMediaQuery } from "@mui/material";
import CommonTable from "./CommonTable";
import theme from "../../theme";

type Row = {
  id: number;
  key: string;
  [key: string]: string | number;
};

export default function Comparetable({ data }: any) {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const keys = Object.keys(data[0]);

  const rows: Row[] = keys.map((key, index) => {
    const row: Row = { id: index, key };
    data.forEach((item: any, itemIndex: number) => {
      row[`Value${itemIndex}`] =
        typeof item[key] == "number" && !Number.isInteger(item[key])
          ? item[key].toFixed(2)
          : item[key] || "Data Not Available";
    });
    return row;
  });

  const columns = [
    { field: "key", headerName: "Key", width: 200 },
    ...data.map((_: any, index: number) => ({
      field: `Value${index}`,
      headerName: `Value ${index + 1}`,
      width: 300,
    })),
  ];

  return (
    <Box
      mt={isSmallScreen ? 2 : 4}
      display="flex"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      margin="auto"
    >
      <CommonTable data={rows} columns={columns} />
    </Box>
  );
}
