import {
  Avatar,
  Button,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import LogoutTwoToneIcon from "@mui/icons-material/LogoutTwoTone";
import { useState } from "react";
import { useAuth } from "../provider/auth_provider";
import { useSearchParams } from "react-router-dom";

const Profile = () => {
  const { userDetails, handleLogout } = useAuth();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const [params] = useSearchParams();
  const userInfo: string = params.get("user_info")?.replaceAll(" ", "+") || "";

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!userInfo) setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        size="medium"
        aria-controls={open ? "profile-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          color: "#444746",
          fontSize: "1rem !important",
          cursor: userInfo ? "auto" : "pointer",
          minWidth: "auto",
          px: 1,
        }}
      >
        <Avatar sx={{ width: 24, height: 24 }}>{userDetails?.name[0]}</Avatar>
      </Button>

      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ "aria-labelledby": "profile-button" }}
        sx={{ zIndex: 99999 }}
      >
        <MenuItem onClick={handleLogout} sx={{ fontSize: 14 }}>
          <LogoutTwoToneIcon
            sx={{ fontSize: isSmallScreen ? 14 : 16, marginRight: "0.5rem" }}
          />
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default Profile;
