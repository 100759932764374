import React from "react";
import { Typography, Container, Box } from "@mui/material";
import logo from "../assets/HDFC-Ergo-logo.png";

export default function LandingFallback() {
  return (
    <Container
      maxWidth="sm"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "80vh",
      }}
    >
      <Box
        component="img"
        src={logo}
        alt="Company Logo"
        sx={{ mb: 6, width: "9.375rem" }}
      />

      <Typography
        variant="h4"
        component="h1"
        color="error"
        fontWeight="bold"
        align="center"
        gutterBottom
      >
        Access Denied
      </Typography>

      <Typography variant="body1" align="center" color="textPrimary" paragraph>
        We are excited to announce that our bot is available to a selected group
        of users. <br /> <br />
        If you are interested in gaining access. <br />
        Please drop an email at{" "}
        <a href="mailto:ama.support@hdfcergo.com">
          ama.support@hdfcergo.com
        </a>. <br />
        Our team will be delighted to assist you with your request.
      </Typography>
    </Container>
  );
}
