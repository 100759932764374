import React, { useState } from "react";
import CommonDialog from "./CommonDialog";
import { Box, Button } from "@mui/material";
import { messages, policyPoints } from "../utils/messages";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useAuth } from "../provider/auth_provider";

const gethowUserAgreementDialogStatus = () => {
  const status = localStorage.getItem("agreementDialogShown");
  if (!status) {
    return true;
  }
};

export default function UserAgreement() {
  const [showUserAgreementDialog, setShowUserAgreementDialog] = useState(
    gethowUserAgreementDialogStatus()
  );
  const [readMore, setReadMore] = useState(false);
  const [userAgreementCheck, setUserAgreementCheck] = useState(false);
  const { handleLogout } = useAuth();

  const handleAgree = () => {
    setShowUserAgreementDialog(false);
    localStorage.setItem("agreementDialogShown", JSON.stringify(true));
  };

  const handleDisagree = () => {
    setShowUserAgreementDialog(false);
    handleLogout();
  };

  const handleCheckboxToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserAgreementCheck(event.target.checked);
  };

  return (
    <>
      {showUserAgreementDialog && (
        <CommonDialog
          open={showUserAgreementDialog}
          onClose={() => {}}
          title="User Agreement"
        >
          <>
            <Box fontSize={14}>
              {readMore ? (
                <>
                  {messages?.userAgreementFull}
                  <ul>
                    {policyPoints?.map((agreementPoints, index) => {
                      return (
                        <li key={`agreement ${index}`}>{agreementPoints}</li>
                      );
                    })}
                  </ul>
                  {messages?.userAcknowledgment}
                </>
              ) : (
                <>{messages?.userAgreementTruncated}</>
              )}
            </Box>
            <Button
              variant="text"
              onClick={() => setReadMore((prev) => !prev)}
              sx={{ ml: -1 }}
            >
              {readMore ? "Show Less" : "Read More"}
            </Button>
            <Box>
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: 13,
                    fontWeight: 600,
                  },
                }}
                control={
                  <Checkbox
                    checked={userAgreementCheck}
                    onChange={handleCheckboxToggle}
                    color="primary"
                  />
                }
                label="I accept the Terms and Conditions"
              />
            </Box>
            <Box display="flex" justifyContent="flex-end" gap={1}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAgree}
                disabled={!userAgreementCheck}
              >
                Agree
              </Button>
              <Button variant="outlined" color="error" onClick={handleDisagree}>
                Disagree
              </Button>
            </Box>
          </>
        </CommonDialog>
      )}
    </>
  );
}
