import { Box, IconButton, Modal } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSnackbar } from 'notistack';

const DebugModal = ({ open, handleModal, conversation }: { open: boolean, handleModal(): void, conversation: any }) => {
    const { enqueueSnackbar } = useSnackbar();
    
    // Function to copy the content to clipboard
    const handleCopy = () => {
        const sqlQuery = conversation?.sqlQuery || "NA";
        const refinedQuery = conversation?.raw?.user_query || "NA";
        const jsonResult = conversation?.raw ? JSON.stringify(conversation.raw) : "NA";
        const message = conversation?.message ? JSON.stringify(conversation.message) : "NA";
        const question = conversation?.query ? JSON.stringify(conversation.query) : "NA";
        const sqlStatus = conversation?.isPlottable ? conversation.isPlottable.toString() : "NA";

        const copyText = `Question: ${question}\nRefined Query: ${refinedQuery}\nMessage: ${message}\nSQL Status: ${sqlStatus}\nSQL Query: ${sqlQuery}\nJSON Result: ${jsonResult}`;
        
        // Copy the text to the clipboard
        navigator.clipboard.writeText(copyText)
            .then(() => {
                enqueueSnackbar("Text copied to clipboard", { variant: 'success' });
                handleModal();
            })
            .catch(err => {
                enqueueSnackbar(`Failed to copy text: ${err.message}`, { variant: 'error' });
            });
    };

    return (
        <Modal
            open={open}
            onClose={handleModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: "absolute" as "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "auto",
                    maxWidth: "90vw",
                    minWidth: "20vw",
                    maxHeight: "70vh",
                    bgcolor: "background.paper",
                    borderRadius: 1,
                    boxShadow: 24,
                    p: 3,
                    overflow: "hidden",
                    overflowY: "auto",
                    display: "block",
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "end" }} onClick={handleCopy}>
                    <IconButton aria-label="copy">
                        <ContentCopyIcon />
                    </IconButton>
                </Box>
                {conversation && 
                    <>
                        <p><span>Question:</span><br/> {conversation.query ? JSON.stringify(conversation.query) : "NA"}</p><br/> 
                        <p><span>Refined Query:</span><br/> {conversation.raw?.user_query ? conversation.raw.user_query : "NA"}</p><br/> 
                        <p><span>Message:</span><br/> {conversation.message ? JSON.stringify(conversation.message) : "NA"}</p><br/> 
                        <p><span>SQL Status:</span><br/> {conversation.isPlottable ? conversation.isPlottable.toString() : "NA"}</p><br/>
                        <p><span>SQL Query:</span><br/> {conversation.sqlQuery ? conversation.sqlQuery : "NA"}</p><br/> 
                        <p><span>JSON result:</span><br/> {conversation.raw ? JSON.stringify(conversation.raw) : "NA"}</p><br/> 
                    </>
                }
            </Box>
        </Modal>
    )
}

export default DebugModal;
