import { createBrowserRouter, Navigate } from "react-router-dom";
import { ChatbotProvider } from "../provider/chatbot_provider";
import Chatbot from "../views/chatbot";
import Landing from "../views/landing";
import Login from "../views/login";
import PrivateRoute from "./privateRoute";
import ErrorBoundary from "./ErrorBoundary";
import PageNotFound from "../components/PageNotFound";

const router = createBrowserRouter([
  {
    path: "/",
    element: <PrivateRoute />,
    hasErrorBoundary: true,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "/",
        element: <Navigate to="/dashboard" />,
      },
      {
        path: "/dashboard",
        element: <Landing />,
      },
      {
        path: "/people",
        element: (
          <ChatbotProvider tool={"hr"}>
            <Chatbot tool="AMA @ People" />
          </ChatbotProvider>
        ),
      },
      {
        path: "/company",
        element: (
          <ChatbotProvider tool={"competitor"}>
            <Chatbot tool="AMA @ Company" />
          </ChatbotProvider>
        ),
      },
      {
        path: "/product",
        element: (
          <ChatbotProvider tool={"catalog"}>
            <Chatbot tool="AMA @ Product" />
          </ChatbotProvider>
        ),
      },
      {
        path: "/document",
        element: (
          <ChatbotProvider tool={"document"}>
            <Chatbot tool="AMA @ Document" />
          </ChatbotProvider>
        ),
      },
      {
        path: "/help",
        element: (
          <ChatbotProvider tool={"help"}>
            <Chatbot tool="AMA @ Help" />
          </ChatbotProvider>
        ),
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
]);

export default router;
