import React from "react";
import Lottie from "lottie-react";
import groovyWalkAnimation from "./../assets/stars.json";
import { useMediaQuery, useTheme } from "@mui/material";

export default function StarIcon({ isLoading }: { isLoading: boolean }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Lottie
      style={{
        width: isSmallScreen ? 40 : 50,
        transform: isSmallScreen ? "scale(1.4)" : "scale(1.6)",
      }}
      animationData={groovyWalkAnimation}
      loop={isLoading || false}
    />
  );
}
