export const monthOrder = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];

export const COLORS = [
  "#115f9a",
  "#48b5c4",
  "#22a7f0",
  "#1984c5",
  "#76c68f",
  "#a6d75b",
  "#c9e52f",
  "#d0ee11",
  "#d0f400",
  "#ffb400",
  "#d2980d",
  "#a57c1b",
  "#786028",
  "#363445",
  "#48446e",
  "#e1a692",
  "#de6e56",
  "#e14b31",
  "#c23728",
];

export const feedbackRateButtonColors = {
  10: {
    buttonNumber: "10",
    backgroundColor: "#14C15A",
    boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.30)",
    borderColor: "#0F9A47",
  },
  9: {
    buttonNumber: "9",
    backgroundColor: "#7DBC54",
    boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.30)",
    borderColor: "#639A43",
  },
  8: {
    buttonNumber: "8",
    backgroundColor: "#B9D251",
    boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.30)",
    borderColor: "#8FA13E",
  },
  7: {
    buttonNumber: "7",
    backgroundColor: "#E5C845",
    boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.30)",
    borderColor: "#B39B34",
  },
  6: {
    buttonNumber: "6",
    backgroundColor: "#F3BE42",
    boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.30)",
    borderColor: "#C39832",
  },
  5: {
    buttonNumber: "5",
    backgroundColor: "#EE9235",
    boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.30)",
    borderColor: "#C37329",
  },
  4: {
    buttonNumber: "4",
    backgroundColor: "#E87330",
    boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.30)",
    borderColor: "#C05C26",
  },
  3: {
    buttonNumber: "3",
    backgroundColor: "#E66034",
    boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.30)",
    borderColor: "#B34B28",
  },
  2: {
    buttonNumber: "2",
    backgroundColor: "#E45537",
    boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.30)",
    borderColor: "#B23F27",
  },
  1: {
    buttonNumber: "1",
    backgroundColor: "#E42125",
    boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.30)",
    borderColor: "#B2191D",
  },
};

export const selfHelpApplications = [
  { appId: "One.Up", name: "1UP", isSelected: true },
];
