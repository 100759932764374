import { useMemo } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useAuth } from "../provider/auth_provider";
import Profile from "../components/profile";
import UserAgreement from "../components/UserAgreement";
import LandingFallback from "./landingFallback";
import Support from "../components/support";
import { clientName } from "../config";
import theme from "../theme";
import CompetetiveAnalysisImage from "./../assets/landing/competitor-analysis.svg";
import ProductAnalysisImage from "./../assets/landing/product-analysis.svg";
import DocumentAnalyzerImage from "./../assets/landing/document-analyzer.svg";
import SelfHelp from "./../assets/landing/self-help.svg";
import HRPoliciesImage from "./../assets/landing/hr-policies.svg";
import ctrlNLogo from "./../assets/teamlogo.svg";
import logo from "../assets/HDFC-Ergo-logo.png";

type CardItemProps = {
  image: string;
  title: string;
  description: string;
  link: string;
  buttonText: string;
  show: boolean;
};

const CardItem = ({
  image,
  title,
  description,
  link,
  buttonText,
  show,
}: CardItemProps) => {
  if (!show) return null;

  return (
    <Grid item>
      <Card
        elevation={8}
        sx={{
          borderRadius: "1.5rem",
          maxWidth: "220px",
          maxHeight: "420px"
        }}>
        <Chip
          variant="filled"
          label="beta"
          size="small"
          sx={{
            m: "5px",
            float: "right",
            cursor: "default",
            fontSize: "10px",
            color: "white",
            fontWeight: "800",
            background: "linear-gradient(90deg, #a29bfe, #ff7675)",
          }}
        />
        <CardContent sx={{ padding: "1.25rem", paddingBottom: 0 }}>

          <Typography
            gutterBottom
            variant="h6"
            component="div"
            fontWeight={700}
            fontSize={14}
            textAlign="center"
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            color="#4d4848"
            fontWeight={400}
            textAlign="center"
            fontSize={12}
          >
            {description}
          </Typography>

          <div
            style={{
              display: "flex",
              justifyItems: "center",
              alignItems: "center",
            }}
          >
            <img
              src={image}
              alt={title}
              height="180"
              style={{ width: "80%", margin: "auto" }}
            />
          </div>
        </CardContent>

        <CardActions
          sx={{
            padding: "0.5rem 1rem 1rem 1rem",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Link to={link}>
            <Button
              sx={{
                color: "#171515",
                borderRadius: "3.75rem",
                border: "0.125rem solid #171515",
                fontSize: "0.8rem",
                padding: "0.25rem 1.5rem",
                "&:hover": {
                  color: "white",
                  backgroundColor: "#171515",
                },
              }}
            >
              {buttonText}
            </Button>
          </Link>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default function Landing() {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const {
    userDetails: { scopes },
  } = useAuth();

  const cardData = useMemo(() => {
    return [
      {
        image: HRPoliciesImage,
        title: "AMA @ People",
        description: "All you need to know about HR Policies",
        link: "/people",
        buttonText: "Explore",
        show: scopes?.includes("people"),
      },
      {
        image: CompetetiveAnalysisImage,
        title: "AMA @ Company",
        description: "All you need to know about Competitor Analysis",
        link: "/company",
        buttonText: "Explore",
        show: scopes?.includes("company"),
      },
      {
        image: ProductAnalysisImage,
        title: "AMA @ Product",
        description: "All you need to know about Product Catalogue",
        link: "/product",
        buttonText: "Explore",
        show: scopes?.includes("product"),
      },
      {
        image: DocumentAnalyzerImage,
        title: "AMA @ Document",
        description: "All you need to know about Document Analyzer",
        link: "/document",
        buttonText: "Explore",
        show: scopes?.includes("document"),
      },
      {
        image: SelfHelp,
        title: "AMA @ Help",
        description: "All you need to know about FAQ's",
        link: "/help",
        buttonText: "Explore",
        show: scopes?.includes("help"),
      },
    ];
  }, [scopes]);

  return (
    <>
      <UserAgreement />

      <Box
        bgcolor={"hsla(215, 15%, 97%, 0.5)"}
        height={"100svh"}
        px={2}
        pt={2}
        overflow="auto"
      >
        <Box display="flex" alignContent="flex-end" justifyContent="flex-end">
          <Profile />
        </Box>
        {!scopes || !scopes?.length ? (
          <LandingFallback />
        ) : (
          <Box>
            {/* Logo and Title Section */}
            <Box sx={{ pt: "0 !important" }}>
              <Box
                display={"flex"}
                justifyContent="center"
                alignItems={"center"}
                mb={1}
                gap={2}
              >
                <img
                  src={logo}
                  alt="HDFC Ergo Logo"
                  height={isSmallScreen ? 25 : 30}
                  style={{ objectFit: "contain" }}
                />
                <Typography
                  align="center"
                  fontSize={isSmallScreen ? "1.125rem" : "1.5rem"}
                  padding={isSmallScreen ? 1 : 0}
                  fontWeight={700}
                  textTransform={"uppercase"}
                >
                  HDFC ERGO - {clientName}
                </Typography>
              </Box>

              <Typography
                variant="body1"
                align="center"
                fontSize={13}
                color={"#4d4848"}
              >
                Ask me anything! A secure internal platform for employees to
                explore Gen AI's potential.
              </Typography>
              <Typography
                variant="body1"
                align="center"
                mb={3}
                fontSize={13}
                color={"#4d4848"}
              >
                Get answers through human like conversations, generate
                quality content and more.
              </Typography>
            </Box>
            <Grid
              container
              spacing={2}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid item md={12}>
                <Grid container spacing={2} justifyContent={"center"}>
                  {/* Cards Section */}
                  {cardData.map((card) => (
                    <CardItem key={card.title} {...card} />
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
            // position: isSmallScreen ? "static" : "fixed",
            bottom: "0.125rem",
            pt: isSmallScreen ? 4 : 1,
            pb: 1,
          }}
        >
          <Box display={"flex"} alignItems={"center"}>
            <Typography fontWeight={500} mr={0.6} fontSize={13}>
              Powered by
            </Typography>
            <img src={ctrlNLogo} alt="ctrl n" height={40} />
          </Box>
          {scopes?.length > 0 && <Support />}
        </Box>
      </Box>
    </>
  );
}
