import { useState, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
} from "@mui/material";
import { monthOrder } from "../../utils/constant";

type CommonTableProps = {
  columns: {
    minWidth: number;
    field: any;
    headerName: string;
    type: string;
    valueFormatter: (params: any) => any;
  }[];
  data: Record<string, any>[];
};

export default function CommonTable({ columns, data }: CommonTableProps) {
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [sortField, setSortField] = useState<string | null>(null);

  const transformedData = useMemo(() => {
    return data.map((item) => {
      const row: any = { id: item.id || item.name };
      columns.forEach(({ field }) => {
        row[field] = item[field] !== undefined ? item[field] : "-";
      });
      return row;
    });
  }, [data, columns]);

  const handleSort = (field: string) => {
    setSortDirection((prevDirection) =>
      prevDirection === "asc" ? "desc" : "asc"
    );
    setSortField(field);
  };

  const sortedData = useMemo(() => {
    if (!sortField) return transformedData;
    return [...transformedData].sort((a, b) => {
      const column = columns.find(({ field }) => field === sortField);
      const isNumberField = column?.type === "number";
      let comparison = 0;
      if (isNumberField) {
        comparison = (a[sortField] || 0) - (b[sortField] || 0);
      } else if (sortField === "month") {
        comparison =
          monthOrder.indexOf(a[sortField].toLowerCase()) -
          monthOrder.indexOf(b[sortField].toLowerCase());
      } else {
        comparison = (a[sortField] || "").localeCompare(b[sortField] || "");
      }
      return sortDirection === "asc" ? comparison : -comparison;
    });
  }, [transformedData, sortField, sortDirection, columns]);

  return (
    <TableContainer
      component={Paper}
      sx={{ maxWidth: "100%", overflowX: "hidden", boxShadow: "none" }}
    >
      <Table>
        <TableHead>
          <TableRow>
            {columns.map(({ field, headerName, minWidth }) => (
              <TableCell key={field} style={{ minWidth }}>
                <TableSortLabel
                  active={sortField === field}
                  direction={sortField === field ? sortDirection : "asc"}
                  onClick={() => handleSort(field)}
                >
                  {headerName}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData.map((row) => (
            <TableRow key={row.id}>
              {columns.map(({ field }) => (
                <TableCell key={field}>{row[field]}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
