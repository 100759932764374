import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { ReactElement } from 'react';

interface CommonDialogProps {
  children: ReactElement;
  open: boolean;
  onClose: () => void;
  title: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

export default function CommonDialog(
  { children,
    open,
    onClose,
    title,
    size,
  }: CommonDialogProps) {
  return (
    <Dialog open={open} onClose={onClose}
      BackdropProps={{
        sx: {
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          backdropFilter: 'blur(0.625rem)',
          WebkitBackdropFilter: 'blur(0.625rem)',
        },
      }}
      maxWidth={size}
    >
      <DialogTitle fontWeight={700}>{title}</DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
    </Dialog>
  );
}
