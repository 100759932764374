import React, { useContext, useState } from "react";
import { decrypt } from "../utils/encryption";
import { IAuthState, IUser } from "../types/user";
import { getClient } from "../utils/http";

const userEmptyState = {
  userId: "",
  name: "",
  username: "",
  accessToken: "",
  scopes: [],
};

const AuthContext = React.createContext<IAuthState | null>(null);

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}

const getUser = () => {
  const userDetails = localStorage.getItem("userDetails");
  if (userDetails) {
    return JSON.parse(decrypt(userDetails));
  } else {
    return userEmptyState;
  }
};

const getSource = () => {
  const source = localStorage.getItem("source");
  if (source) {
    return source;
  } else {
    return "";
  }
};

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [userDetails, setUserDetails] = useState<IUser>(getUser());
  const [source, setSource] = useState<string>(getSource());

  const handleLogout = async () => {
    try {
      await getClient("document").post("/delete-file");
      setUserDetails(userEmptyState);
      localStorage.removeItem("userDetails");
      window.history.pushState("", "", "/login");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AuthContext.Provider
      value={{ userDetails, setUserDetails, handleLogout, source, setSource }}
    >
      {children}
    </AuthContext.Provider>
  );
}
