import { getClient } from "../utils/http";
import { decrypt, encrypt } from "../utils/encryption";

export const uploadFile = async (
  vector_name: string,
  file_name: string,
  base64File: string
) => {
  const payload = {
    vector_name: vector_name,
    file_name: file_name,
    base64File: base64File,
  };
  const response = await getClient("document").post("/upload-file", {
    data: encrypt(JSON.stringify(payload)),
  });
  return response.status;
};

export const getFilesList = async (vector_name: string) => {
  const payload = { vector_name: vector_name };
  const response = await getClient("document").post("/list-files", {
    data: encrypt(JSON.stringify(payload)),
  });
  const data = JSON.parse(decrypt(response.data.data));
  return { files: data.files, questionSuggestions: data.questionSuggestions };
};
