import React from "react";
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormGroup,
  Radio,
  Typography,
} from "@mui/material";
import useChatbot from "../provider/chatbot_provider";

interface modalTypes {
  handleClose: () => void;
}
export default function ManageHelpModal({ handleClose }: modalTypes) {
  const { applications, setApplications } = useChatbot();

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { id } = event?.target;
    setApplications((prev) => {
      let updated = prev?.map((obj) => {
        if (obj?.appId === id) return { ...obj, isSelected: true };
        return { ...obj, isSelected: false };
      });
      return updated;
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        // alignItems: "center",
        // justifyContent: "space-between",
        gap: "0.625rem",
        flexWrap: "wrap",
        minWidth: "30vw",
      }}
    >
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        sx={{ fontWeight: 600, fontSize: "1.2rem" }}
      >
        Select Application
      </Typography>

      <Divider />

      <FormGroup sx={{ pb: 1 }}>
        {applications?.map((obj: Record<string, any>, index: number) => {
          return (
            <FormControlLabel
              key={index}
              control={
                <Radio
                  id={obj?.appId}
                  checked={obj?.isSelected}
                  onChange={handleRadioChange}
                />
              }
              label={obj.name}
              sx={{ width: "100%" }}
              componentsProps={{
                typography: {
                  sx: { fontSize: "0.8rem" },
                },
              }}
            />
          );
        })}
      </FormGroup>

      <Divider />

      <Button
        variant="text"
        color="primary"
        sx={{ p: 1, mt: 1.5, width: "unset", alignSelf: "end" }}
        onClick={() => {
          handleClose();
        }}
      >
        Close
      </Button>
    </Box>
  );
}
