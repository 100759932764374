import { Box, useMediaQuery } from "@mui/material";
import { capitalize } from "../../utils/common";
import CommonTable from "./CommonTable";
import theme from "../../theme";

export default function Datatable({ data }: any) {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const columns = Object.keys(data[0]).map((key: any) => {
    return {
      minWidth: 100,
      field: key,
      headerName: capitalize(key.replace(/_/gi, " ")),
      type:
        typeof data[0][key] === "number" && key !== "Year"
          ? "number"
          : "string",
      valueFormatter:
        key === "Year"
          ? (params: any) => params?.value?.toString()
          : (params: any) => params?.value,
    };
  });

  const rows = data.map((row: any, index: number) => {
    const newRow = { ...row, id: index };
    Object.keys(newRow).forEach((key) => {
      const item = newRow[key];
      if (typeof item == "number" && !Number.isInteger(item)) {
        newRow[key] = item?.toFixed(2);
      } else if (item === null || item === undefined) {
        newRow[key] = "Data Not Available";
      } else if (typeof item == "string") {
        newRow[key] = capitalize(item);
      }
    });
    return newRow;
  });

  return (
    <Box
      mt={isSmallScreen ? 2 : 4}
      display="flex"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      margin="auto"
    >
      <CommonTable data={rows} columns={columns} />
    </Box>
  );
}
