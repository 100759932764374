import { useEffect } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { RouterProvider } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { AuthProvider } from "./provider/auth_provider";
import { detectOS } from "./utils/osDetection";
import router from "./routes/router";
import theme from "./theme";
import "./assets/css/global.css";

export default function App() {
  useEffect(() => {
    const osClass = detectOS();
    document.body.classList.add(osClass);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <SnackbarProvider>
          <AuthProvider>
            <RouterProvider router={router} />
          </AuthProvider>
        </SnackbarProvider>
      </div>
    </ThemeProvider>
  );
}
