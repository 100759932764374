import { EmailOutlined } from "@mui/icons-material";
import { Box, SxProps } from "@mui/material";

type supportProps = {
  sx?: SxProps;
};

function Support({ sx }: supportProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        justifyContent: "center",
        alignItems: "center",
        fontSize: "0.75rem",
        ...sx
      }}
    >
      For any support,&nbsp;
      <Box sx={{ display: "flex", alignItems: "center", gap: 0.6 }}>
        <EmailOutlined sx={{ fontSize: "1.2rem", color: "#d32f2f" }} />
        <a href="mailto:ama.support@hdfcergo.com">ama.support@hdfcergo.com</a>
      </Box>
    </Box>
  );
}

export default Support;
