import axios, { AxiosInstance } from "axios";
import { decrypt } from "./encryption";
const baseURL = process.env.REACT_APP_BASE_URL;

const createAxiosInstance = (endpoint: string = "") => {
  const newInstance = axios.create({ baseURL: `${baseURL}/${endpoint}` });

  newInstance.interceptors.request.use(function (request) {
    const userDetails = localStorage.getItem("userDetails")!;
    if (userDetails) {
      const { method, data } = request;
      if (method === "post" && !data) request.data = {};
      let token = JSON.parse(decrypt(userDetails || ""))?.accessToken;
      request.headers.Authorization = `Bearer ${token}`;
      request.data.user = userDetails;
    }
    return request;
  });

  newInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (
        error.response &&
        error.response.status === 401 &&
        window.location.pathname !== "/login"
      ) {
        // Access Token was expired
        localStorage.removeItem("userDetails");
        window.location.pathname = "/login";
      }
      return Promise.reject(error);
    }
  );

  return newInstance;
};

const hrClient = createAxiosInstance("hr-bot");

const documentClient = createAxiosInstance("doc-analyzer");

const competitorClient = createAxiosInstance("competative-analysis");

const productClient = createAxiosInstance("product-catalogue/v2");

const baseClient = createAxiosInstance();

export const tools: Record<string, AxiosInstance> = {
  hr: hrClient,
  competitor: competitorClient,
  catalog: productClient,
  document: documentClient,
  help: documentClient,
  default: baseClient,
};

export const getClient = (tool: string = "default") => {
  return tools[tool];
};
