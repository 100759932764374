import { styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";

interface customProps extends TooltipProps {
  children: JSX.Element;
  bgcolor?: string;
  maxwidth?: string;
}

const BootstrapTooltip = styled(({ className, ...props }: customProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme, color, bgcolor, maxwidth }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: bgcolor,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: bgcolor,
    color,
    maxwidth,
  },
}));

function CustomTooltip({
  children,
  title,
  color = "black",
  bgcolor = "#fbe2e0",
  maxwidth = "18rem",
}: customProps) {
  return (
    <BootstrapTooltip
      title={title}
      color={color}
      bgcolor={bgcolor}
      maxwidth={maxwidth}
    >
      {children}
    </BootstrapTooltip>
  );
}

export default CustomTooltip;
