import { Box, useMediaQuery } from "@mui/material";
import theme from "../../theme";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { capitalize, roundOff } from "../../utils/common";
import { COLORS } from "../../utils/constant";

const formatData = (data: any) => {
  const groupedData: { [key: string]: any } = {};

  const lastIndex = data.length - 1;
  const dynamicValueKey = Object.keys(data[lastIndex]).find((key) => {
    return typeof data[0][key] === "number" && !key.includes("year");
  });
  const dynamicLabelKey = Object.keys(data[lastIndex]).find((key) => {
    return typeof data[0][key] === "string" && !key.includes("month");
  });

  data.forEach((item: any) => {
    const key = `${item.month}-${item.year}`;
    if (!groupedData[key]) {
      groupedData[key] = {
        month: item.month,
        year: item.year,
        xLabel: `${capitalize(item.month as string).slice(0, 3)} ${item.year
          ?.toString()
          .slice(-2)}`,
      };
    }
    const currentGroup = groupedData[key];
    const companyIndex =
      Object.keys(currentGroup).filter((k) => k.includes("name")).length + 1;
    const label = item?.[dynamicLabelKey as string];
    currentGroup[`name${companyIndex}`] = capitalize(label);
    const val = item?.[dynamicValueKey as string];
    currentGroup[`value${companyIndex}`] = roundOff(val);
  });
  return Object.values(groupedData);
};

export default function Barchart({ data }: any) {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  let dataKeys = Object.keys(data[0]);

  if (dataKeys.includes("year") || dataKeys.includes("Year")) {
    const formattedData = formatData(data);

    let barNames: string[] = [];
    const transformedData = formattedData.map((item: any, index: number) => {
      const transformedItem: any = { xLabel: item.xLabel };
      let i = 1;
      while (item[`value${i}`] !== undefined) {
        transformedItem[`value${i}`] = item[`value${i}`];
        if (index === 0) {
          barNames.push(`value${i}`);
        }
        i++;
      }
      return transformedItem;
    });

    barNames = barNames.filter(
      (value, index, self) => self.indexOf(value) === index
    );

    return (
      <Box
        width="100%"
        display="flex"
        justifyItems="center"
        margin="auto"
        pt={isSmallScreen ? 2 : 4}
        ml={isSmallScreen ? -5 : 0}
      >
        <BarChart
          width={isSmallScreen ? 400 : 600}
          height={300}
          data={transformedData}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="xLabel" tick={{ fontSize: 14 }} />
          <YAxis />
          <Tooltip formatter={(value) => roundOff(value as number)} />
          <Legend verticalAlign="bottom" layout="vertical" />
          {barNames.map((barName, index) => (
            <Bar
              key={index}
              dataKey={barName}
              name={(formattedData[0] as any)[`name${index + 1}`]}
              fill={COLORS[index]}
            />
          ))}
        </BarChart>
      </Box>
    );
  } else if (dataKeys.length > 2) {
    dataKeys = Object.keys(data[0]).filter((item) => item !== "name");
    return (
      <Box
        width="100%"
        display="flex"
        justifyItems="center"
        margin="auto"
        pt={isSmallScreen ? 2 : 4}
        ml={isSmallScreen ? -5 : 0}
      >
        <BarChart width={isSmallScreen ? 360 : 600} height={300} data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" tick={{ fontSize: 14 }} />
          <YAxis />
          <Tooltip formatter={(value) => roundOff(value as number)} />
          <Legend verticalAlign="bottom" layout="vertical" />
          {dataKeys.map((barName, index) => (
            <Bar key={index} dataKey={barName} fill={COLORS[index]} />
          ))}
        </BarChart>
      </Box>
    );
  } else {
    const formattedData = data.map((item: any, index: any) => {
      let valueKey = Object.keys(item).find(
        (key) =>
          typeof item[key] === "number" && !["month", "year"].includes(key)
      );
      let labelKey = Object.keys(item).find(
        (key) => typeof item[key] === "string"
      );
      return {
        id: index,
        value: roundOff(item[valueKey!]),
        label: capitalize(item[labelKey!]),
      };
    });

    return (
      <Box
        width="100%"
        display="flex"
        justifyItems="center"
        margin="auto"
        pt={isSmallScreen ? 2 : 3}
        ml={isSmallScreen && formattedData?.length > 3 ? -10 : -3}
        sx={{ width: "100%" }}
      >
        <BarChart
          width={isSmallScreen ? 400 : 650}
          height={400}
          data={formattedData}
          layout={formattedData?.length > 3 ? "vertical" : "horizontal"}
        >
          <CartesianGrid strokeDasharray="3 3" />
          {formattedData?.length > 3 ? (
            <>
              <XAxis type="number" />
              <YAxis
                dataKey="label"
                type="category"
                width={200}
                tick={{ fontSize: 14 }}
                tickFormatter={(name) => {
                  const maxLength = isSmallScreen ? 15 : 25;
                  return name.length > maxLength
                    ? `${name.substring(0, 15)}...`
                    : name;
                }}
              />
            </>
          ) : (
            <>
              <YAxis type="number" />
              <XAxis
                dataKey="label"
                type="category"
                width={200}
                tick={{ fontSize: 14 }}
                tickFormatter={(name) => {
                  const maxLength = isSmallScreen ? 20 : 30;
                  return name.length > maxLength
                    ? `${name.substring(0, 15)}...`
                    : name;
                }}
              />
            </>
          )}
          <Tooltip formatter={(value) => roundOff(value as number)} />
          <Legend />
          <Bar dataKey="value" fill="#8884d8" />
        </BarChart>
      </Box>
    );
  }
}
