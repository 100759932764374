import { InputAdornment, TextField, IconButton } from "@mui/material";
import React, { ChangeEvent, MouseEvent } from "react";

interface CommonInputProps {
  label?: string;
  onChangeHandler?: (event: ChangeEvent<HTMLInputElement>) => void;
  onClickHandler?: (event: MouseEvent<HTMLButtonElement>) => void;
  showPassword?: boolean;
  leftAlign?: boolean;
  passwordIconOff?: React.ReactNode;
  passwordIcon?: React.ReactNode;
  password?: boolean;
  placeholder?: string;
  value?: string;
  id: string | undefined;
}

const CommonInput: React.FC<CommonInputProps> = ({
  label = "Default",
  onChangeHandler = () => { },
  onClickHandler = () => { },
  showPassword = false,
  passwordIconOff,
  passwordIcon,
  password = false,
  placeholder = "Default",
  value,
  id = "Default",
}) => {

  const FilledInputProp = {
    endAdornment: password ? (
      <InputAdornment position="end">
        <IconButton onClick={onClickHandler} edge="end">
          {showPassword ? passwordIconOff : passwordIcon}
        </IconButton>
      </InputAdornment>
    ) : null,
  };

  return (
    <>
      <p style={{ fontWeight: "500", fontSize: "0.875rem", color: "#252E3D" }}>
        {label}
      </p>
      <TextField
        id={id}
        autoComplete=""
        variant="outlined"
        value={value}
        size="small"
        type={showPassword ? "password" : "text"}
        sx={{
          width: "100%",
          fontSize: "1.1rem",
          marginTop: "0.16rem",
          marginBottom: "1rem",
          '& .MuiInputBase-input::placeholder': {
            fontSize: "0.85rem",
          },
          "& .MuiOutlinedInput-root": {
            borderRadius: "0.3125rem",
            "& fieldset": {
              borderColor: "#E9EAEC",
            },
          },
        }}

        InputProps={FilledInputProp}
        placeholder={placeholder}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          onChangeHandler(event)
        }
      />
    </>
  );
};

export default CommonInput;
