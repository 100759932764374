import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { IChat, IChatbotState } from "../types/chatbot";
import { nanoid } from "nanoid";
import { useLocation, useNavigate } from "react-router-dom";
import { getClient } from "../utils/http";
import { decrypt, encrypt } from "../utils/encryption";
import { sanitizeUserInputQuery } from "../utils/common";
import { useAuth } from "./auth_provider";
import { selfHelpApplications } from "../utils/constant";

const datasetName = process.env.REACT_APP_DATASET_NAME;
const perspective = process.env.REACT_APP_PERSPECTIVE;

const ChatbotContext = createContext<IChatbotState | null>(null);

const getNewChat = () => {
  return { sessionId: nanoid(), title: "New chat", conversations: [] };
};

export function ChatbotProvider({
  children,
  tool,
}: PropsWithChildren<{ tool: string }>) {
  const [selectedChat, setSelectedChat] = useState(0);
  const [selectedQuery, setSelectedQuery] = useState<number | null>(null);
  const [last5Queries, setLast5Queries] = useState<any>([]);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState<string>();
  const [fileList, setFileList] = useState([]);
  const [applications, setApplications] = useState(selfHelpApplications);
  const [chats, setChats] = useState<IChat[]>([getNewChat()]);
  const { pathname } = useLocation();
  const { source } = useAuth();
  const navigate = useNavigate();
  const conversationsContainerRef = useRef<HTMLDivElement>();

  const isChatLoading = useMemo(() => {
    let conversations = chats?.[selectedChat]?.conversations;
    return !!conversations?.[conversations?.length - 1]?.isLoading;
  }, [chats, selectedChat]);

  const scrollDown = () => {
    if (conversationsContainerRef.current) {
      conversationsContainerRef.current.scrollTop =
        conversationsContainerRef.current.scrollHeight;
    }
  };

  const newChat = () => {
    setChats([...chats, getNewChat()]);
    setSelectedChat(chats.length);
  };

  const deleteChat = (event: React.MouseEvent<HTMLElement>, id: string) => {
    event.preventDefault();
    event.stopPropagation();
    const filteredChats = chats.filter((chat) => chat.sessionId !== id);
    if (filteredChats.length === 0) {
      filteredChats.push(getNewChat());
    }
    if (selectedChat === chats.length - 1) {
      setSelectedChat(filteredChats.length - 1);
    }
    setChats([...filteredChats]);
  };

  const addConversation = (
    query: string,
    locale: string,
    vectorName: string
  ) => {
    setChats((chats) => {
      chats?.[selectedChat]?.conversations?.push({
        query: query,
        locale: locale,
        isLoading: true,
      });

      const updatedChats = [...chats];
      fetchAnswer(
        updatedChats?.[selectedChat]?.conversations?.length - 1,
        vectorName
      );
      return updatedChats;
    });
  };

  const addConversationFromHistory = (data: any) => {
    const request = JSON.parse(data.request);
    const response = JSON.parse(data.response);
    const conversations = chats?.[selectedChat]?.conversations;

    if (conversations?.[conversations?.length - 1]?.query === request?.query) {
      return;
    }

    setChats([
      ...chats,
      {
        sessionId: nanoid(),
        title: "New chat",
        conversations: [
          {
            query: request?.query,
            locale: response?.locale,
            isLoading: false,
            sqlQuery: response?.sql_query,
            result: response?.result,
            isPlottable: response?.is_plottable,
            message: response?.message,
            displyType: response?.displyType,
            questionSuggestion: response?.questionSuggestion,
            raw: response,
            url: response?.insuranceUrl,
            comparison: response?.comparison,
          },
        ],
      },
    ]);
    setSelectedChat(chats.length);
  };

  const passSuggestion = (suggestion: string) => {
    setSelectedSuggestion(suggestion);
  };

  const like = (chatIndex: number) => {
    if (selectedQuery === null) {
      feedbackSubmit(chatIndex, true);
      return;
    }

    if (selectedQuery !== null) {
      setLast5Queries((queries: any) => {
        queries[selectedQuery].conversations[0].isLiked = true;
        return [...queries];
      });
    } else {
      setChats((chats) => {
        chats[selectedChat].conversations[chatIndex].isLiked = true;
        return [...chats];
      });
    }
  };

  const dislike = (chatIndex: number) => {
    if (selectedQuery === null) {
      feedbackSubmit(chatIndex, false);
      return;
    }
    if (selectedQuery !== null) {
      setLast5Queries((queries: any) => {
        queries[selectedQuery].conversations[0].isLiked = false;
        return [...queries];
      });
    } else {
      setChats((chats) => {
        chats[selectedChat].conversations[chatIndex].isLiked = false;
        return [...chats];
      });
    }
  };

  const feedbackSubmit = async (chatIndex: number, isLiked: boolean) => {
    try {
      const selectedConversation = chats[selectedChat].conversations[chatIndex];

      const payload: any = {
        // userid : `${chatIndex}${chats[selectedChat].sessionId}${chatIndex}`,
        // sessionid : chats[selectedChat].sessionId,
        questionId: chatIndex + 1,
        request: selectedConversation.query,
        feedBack: isLiked,
      };

      const res: { result: any; comparison?: any } = {
        result: selectedConversation.result,
      };
      const comparison = selectedConversation?.comparison;
      if (comparison && comparison.length > 0) {
        res.comparison = comparison;
      }
      payload.response = res;

      if (tool === "competitor") {
        // payload.sqlQuery = selectedConversation.sqlQuery;
        payload.displayType = selectedConversation.displyType;
      } else if (tool === "catalog" || tool === "document") {
        payload.themeName = selectedConversation.raw.themeName;
      }

      const formattedPayload = { data: encrypt(JSON.stringify(payload)) };
      await getClient(tool).post("/feedback", formattedPayload);

      setChats((chats) => {
        selectedConversation.isLiked = isLiked;
        return [...chats];
      });
    } catch (err) {
      console.log(err);
    }
  };

  const fetchAnswer = async (conversationIndex: number, vectorName: any) => {
    try {
      const isValidPrompt = sanitizeUserInputQuery(
        chats[selectedChat].conversations[conversationIndex].query
      );
      if (!isValidPrompt) throw new Error("Invalid Prompt");

      const payload: any = {
        query: chats[selectedChat].conversations[conversationIndex].query,
        questionId: conversationIndex + 1,
        translationLanguage:
          chats[selectedChat].conversations[conversationIndex].locale,
        userConversation: chats[selectedChat].conversations
          .filter((conversation) => conversation.query && conversation.result)
          .slice(-4)
          .reduce((acc: any[], conversation) => {
            if (conversation.query && conversation.result) {
              acc.push({
                author: "user",
                data: conversation.query,
                // html: conversation.query,
              });
              acc.push({ author: "bot", data: conversation.result });
            }
            return acc;
          }, []),
        // lastSql: previousConversation?.sqlQuery || "",
        datasetName: datasetName,
        perspective: perspective,
      };
      if (tool === "document") {
        payload.vector_name = vectorName;
        payload.files_list = fileList
          ?.filter((fileObj: Record<string, any>) => fileObj?.isSelected)
          ?.map(
            (fileObj: Record<string, any>) => fileObj?.name?.split("/")?.[1]
          );
      }

      if (tool === "help") {
        payload.appId = applications?.filter(
          (obj) => obj?.isSelected
        )?.[0]?.appId;
      }

      const formattedPayload = { data: encrypt(JSON.stringify(payload)) };

      const response = await getClient(tool).post(
        "/analysis",
        formattedPayload
        // { headers: { sessionId: chats[selectedChat].sessionId } }
      );

      const data = JSON.parse(decrypt(response.data.data));

      setChats((chats) => {
        const conversation =
          chats?.[selectedChat]?.conversations?.[conversationIndex];
        if (conversation) {
          const updatedConversation = {
            ...conversation,
            isLoading: false,
            sqlQuery: data.sql_query,
            result: data.result,
            isPlottable: data.is_plottable,
            message: data.message,
            displyType: data.displyType,
            questionSuggestion: data.questionSuggestion,
            raw: data,
            url: data.insuranceUrl,
            comparison: data.comparison,
          };
          const updatedChats = [...chats];
          updatedChats[selectedChat].conversations[conversationIndex] =
            updatedConversation;
          return updatedChats;
        }

        return chats;
      });
    } catch (err) {
      setChats((chats) => {
        const conversation =
          chats?.[selectedChat]?.conversations?.[conversationIndex];
        if (conversation) {
          const updatedConversation = {
            ...conversation,
            isLoading: false,
            isPlottable: false,
          };
          const updatedChats = [...chats];
          updatedChats[selectedChat].conversations[conversationIndex] =
            updatedConversation;
          return updatedChats;
        }
        return chats;
      });
    }
  };

  const fetchChatHistory = useCallback(() => {
    let payload = {
      data: encrypt(
        JSON.stringify({
          serviceName:
            tool === "document"
              ? "Document Analyzer"
              : tool === "help"
              ? "Help Bot"
              : "",
        })
      ),
    };
    getClient(tool)
      .post("/get_chat_history", payload)
      .then(({ data: { data } }) => {
        let chatHistory = JSON.parse(decrypt(data))?.data?.chat_history || [];
        setLast5Queries([...chatHistory]);
      })
      .catch((err) => {
        setLast5Queries([]);
      });
  }, [tool]);

  const promptQueryFromHistory = async (queryObj: any, index: number) => {
    try {
      navigate("/company");

      const hasResult = last5Queries?.[index || 0]?.conversations?.[0]?.result;
      if (hasResult) {
        setSelectedQuery(index);
        return;
      }
      setLast5Queries((queries: any) => {
        queries[index].conversations[0] = {
          query: queryObj.query,
          locale: "en-US",
          isLoading: true,
        };
        return [...queries];
      });

      setTimeout(async () => {
        setSelectedQuery(index);
        let payload: any = {
          query: queryObj.query,
          questionId: 1,
          translationLanguage: "en-US",
          userConversation: [],
          lastSql: "",
          datasetName: datasetName,
          perspective: perspective,
        };

        const formattedPayload = {
          data: encrypt(JSON.stringify(payload)),
        };

        const response = await getClient(tool).post(
          "/analysis",
          formattedPayload
          // { headers: { sessionId: chats[selectedChat].sessionId } }
        );

        const data = JSON.parse(decrypt(response.data));

        setLast5Queries((queries: any) => {
          const conversation = queries?.[index || 0]?.conversations?.[0];

          if (conversation) {
            const updatedConversation = {
              ...conversation,
              isLoading: false,
              sqlQuery: data.sql_query,
              result: data.result,
              isPlottable: data.is_plottable,
              message: data.message,
              displyType: data.displyType,
              questionSuggestion: data.questionSuggestion,
              raw: data,
              url: data.insuranceUrl,
              comparison: data.comparison,
            };

            const updatedChats = [...chats];
            updatedChats[selectedChat].conversations[index] =
              updatedConversation;
            return updatedChats;
          }
          return queries;
        });
      }, 100);
    } catch (err) {
      const conversation = last5Queries?.[index || 0]?.conversations?.[0];
      if (conversation) {
        conversation.isLoading = false;
        conversation.isPlottable = false;
      }
    }
  };

  const updateSuggestions = useCallback((values: string[]) => {
    setSuggestions(values);
  }, []);

  const fetchSuggestions = useCallback(async () => {
    try {
      let payload = null;
      setSuggestions([]);
      if (tool === "competitor") {
        payload = { datasetName, perspective };
      } else if (tool === "catalog") {
        payload = { query: "", userConversation: [] };
      }
      if (!["document", "help"].includes(tool)) {
        const response = await getClient(tool).post("/suggestion", payload);
        const data = JSON.parse(decrypt(response.data.data));
        setSuggestions(data || []);
      }
    } catch (err) {
      setSuggestions([]);
    }
  }, [tool]);

  useEffect(() => {
    scrollDown();
  }, [selectedChat, isChatLoading]);

  useEffect(() => {
    if (!source) {
      fetchChatHistory();
    }
    fetchSuggestions();
    setChats([getNewChat()]);
    setSelectedChat(0);
  }, [fetchChatHistory, fetchSuggestions, pathname, source]);

  return (
    <ChatbotContext.Provider
      value={{
        selectedQuery,
        setSelectedQuery,
        last5Queries,
        promptQueryFromHistory,
        selectedChat,
        setSelectedChat,
        passSuggestion,
        chats,
        selectedSuggestion,
        newChat,
        deleteChat,
        addConversation,
        addConversationFromHistory,
        conversationsContainerRef,
        suggestions,
        updateSuggestions,
        like,
        dislike,
        isChatLoading,
        fileList,
        setFileList,
        applications,
        setApplications,
      }}
    >
      {children}
    </ChatbotContext.Provider>
  );
}

export default function useChatbot(): IChatbotState {
  let context = useContext(ChatbotContext);
  return context!;
}
