import React from "react";
import { Typography, Container, Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function PageNotFound() {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/");
  };

  return (
    <Container maxWidth="sm" style={{ textAlign: "center", marginTop: "4rem" }}>
      <Box>
        <Typography variant="h1" component="h1" color="primary" gutterBottom>
          404
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom>
          Page Not Found
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          Sorry, the page you are looking for does not exist.
        </Typography>
        <Button variant="contained" color="primary" onClick={handleGoBack}>
          Go Back to Home
        </Button>
      </Box>
    </Container>
  );
}
