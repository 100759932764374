import React from "react";
import { Button, Typography, Container, Box } from "@mui/material";
import logo from "../assets/HDFC-Ergo-logo.png";

export default function ErrorBoundary() {
  return (
    <Container
      maxWidth="sm"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Box
        component="img"
        src={logo}
        alt="Error Logo"
        sx={{ mb: 6, width: "9.375rem" }}
      />

      <Typography
        variant="h4"
        component="h1"
        color="error"
        fontWeight="bold"
        align="center"
        gutterBottom
      >
        Something went wrong!
      </Typography>

      <Typography variant="body1" align="center" color="textPrimary" paragraph>
        We&apos;re sorry, some unexpected error occurred! Please try reloading
        the page.
      </Typography>

      <Button
        variant="contained"
        color="primary"
        size="medium"
        onClick={() => {
          window.location.reload();
        }}
      >
        Refresh Page
      </Button>
    </Container>
  );
}
