import {
  Outlet,
  Navigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";

import { useAuth } from "../provider/auth_provider";
import { decrypt } from "../utils/encryption";

export default function PrivateRoute() {
  const { userDetails, setUserDetails } = useAuth();
  const { pathname } = useLocation();
  const [params] = useSearchParams();
  const userInfo: string = params.get("user_info")?.replaceAll(" ", "+") || "";

  const isProtectedRoute = [
    "/people",
    "/product",
    "/company",
    "/document",
    "/help",
  ].includes(pathname);

  const hasAccess =
    userDetails.scopes && userDetails.scopes?.includes(pathname.split("/")[1]);

  if (userInfo) {
    localStorage.setItem("userDetails", userInfo);
    setUserDetails({ ...JSON.parse(decrypt(userInfo)) });
  }

  if (!userDetails.accessToken && !userInfo) {
    return <Navigate to="/login" />;
  }

  if (isProtectedRoute && !hasAccess && !userInfo) {
    return <Navigate to="/dashboard" />;
  }

  return <Outlet />;
}
