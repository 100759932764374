// export const documentBaseURL = "https://hdfce-genai-api.niveussolutions.com/doc-analyzer";

// export const competitorBaseURL = "https://hdfc-genai-comp.niveussolutions.com/competitor-analysis";

// export const productBaseURL = "https://hdfc-genai-prod.niveussolutions.com/product-catalogue";

export const baseURL = "https://genai-api.test-uat.com";

export const datasetName = "insurance_company";
export const perspective = "HDFC-ergo";
export const clientName = "AMA";
export const logoURL = "../src/assets/HDFC-Ergo-logo.png";

export const locales = [
  {
    locale: "English",
    code: "en-US",
  },
  // {
  //   locale: "Malay",
  //   code: "ms-MY",
  // },
  // {
  //   locale: "Chinese",
  //   code: "zh-CN",
  // },
  // {
  //     locale: "Chinese (Taiwan)",
  //     code: "zh-TW"
  // }
];
export const defaultLocale = locales[0].code;
