import * as React from "react";
import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { getFilesList, uploadFile } from "../provider/query-api";
import useChatbot from "../provider/chatbot_provider";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function ManageDocs({
  setVectorName,
  vectorName,
  handleClose,
}: any) {
  // const [vectorNameText, setVectorNameText] = useState("");
  const [fileUploadList, setFileUploadList] = useState([] as any);
  const [uploadLoading, setUploadLoading] = useState(false);
  // const [error, setError] = useState("");
  const { chats, updateSuggestions, fileList, setFileList } = useChatbot();

  async function getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
    });
  }

  const handleFileUpload = async () => {
    setUploadLoading(true);
    /* const base64FileList = */ await Promise.all(
      Array(fileUploadList.length)
        .fill(1)
        .map(async (fileUploadVal: any, index: number) => {
          const fileUploadListBase64 = await getBase64(fileUploadList[index]);
          const uploadStatus = await uploadFile(
            vectorName,
            fileUploadList[index].name,
            fileUploadListBase64 + ""
          );
          return uploadStatus;
        })
    );

    setFileUploadList([]);
    refreshFileList();
    setUploadLoading(false);
  };

  // const handleSubmit = () => {
  //   if (vectorNameText.trim() === "") {
  //     setError("Please enter a profile name.");
  //   } else {
  //     setError("");
  //     setVectorName(vectorNameText);
  //   }
  // };

  const refreshFileList = React.useCallback(() => {
    // if (vectorName) {
    getFilesList(vectorName).then((data) => {
      updateSuggestions(data.questionSuggestions);
      setFileList((prev: Record<string, any>[]) => {
        let updatedFiles = [...prev];
        data.files?.forEach((file: string) => {
          if (
            !updatedFiles?.filter(
              (prevFile: Record<string, any>) => prevFile?.name === file
            )?.length
          )
            updatedFiles.push({
              isSelected: true,
              name: file,
            });
        });
        return updatedFiles;
      });
    });
    // }
  }, [updateSuggestions, vectorName]);

  useEffect(() => {
    refreshFileList();
  }, [refreshFileList, vectorName, chats]);

  return (
    <>
      {/* {!vectorName && (
        <Box sx={{ width: "auto" }}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            mb={1.5}
            sx={{ fontSize: "1.2rem", fontWeight: 600 }}
          >
            Enter Profile Name
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: "0.625rem",
              borderRadius: "0.625rem !important",
            }}
            id="testing"
          >
            <TextField
              error={!!error}
              id="outlined-error-helper-text"
              sx={{
                borderRadius: "0.125rem !important",
                fontSize: 13,
                "& .MuiInputBase-root.MuiOutlinedInput-root": {
                  borderRadius: "0.625rem",
                },
              }}
              // label={error ? "Error" : "Profile Name"}
              value={vectorNameText}
              placeholder="Profile Name"
              helperText={error || " "}
              onChange={(e) => {
                setVectorNameText(e.target.value);
                if (error) {
                  setError("");
                }
              }}
            />
            <Button
              variant="contained"
              color="primary"
              sx={{
                p: 2,
                px: 4,
                width: "unset",
                borderRadius: "0.75rem",
                height: "3.3125rem",
              }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Box>
        </Box>
      )} */}
      {!vectorName && (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "0.625rem",
              flexWrap: "wrap",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ fontWeight: 600, fontSize: "1.2rem" }}
            >
              Add Documents
            </Typography>
            {/* <TextField variant="standard" type="file" /> */}
            {/* <input type="file" multiple onChange={(e) => { console.log(e.target.files?.item(1)?.name) }}  /> */}
            <Button
              component="label"
              role={undefined}
              variant="outlined"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
            >
              Select files
              <VisuallyHiddenInput
                type="file"
                accept="application/pdf"
                multiple
                onChange={(e: any) => {
                  // check if each file size is less than 2 MB
                  const fileList = Array(e.target.files.length)
                    .fill(1)
                    .map((fileVal: any, index: number) => {
                      if (e.target.files.item(index)?.size > 2000000) {
                        alert(
                          `File ${
                            e.target.files.item(index)?.name
                          } is too large`
                        );
                        return null;
                      }
                      return e.target.files.item(index);
                    })
                    .filter((file: any) => {
                      return file;
                    });

                  if (fileList.length > 0) {
                    setFileUploadList(fileList);
                  }
                  // setFileUploadList(e.target.files)
                }}
              />
              (Max. 2 MB)
            </Button>
          </Box>

          <List dense={true}>
            {Array(fileUploadList.length)
              .fill(1)
              .map((fileUploadVal: any, index: number) => {
                return (
                  <ListItem
                    sx={{ px: 0, pt: 1 }}
                    key={`${fileUploadList[index]?.name}+${index}`}
                  >
                    <ListItemText primary={fileUploadList[index]?.name} />
                  </ListItem>
                );
              })}
          </List>

          {fileUploadList.length > 0 && (
            <Button
              variant="contained"
              color="primary"
              sx={{ p: 1, px: 2, width: "unset", mb: 2, fontSize: "0.8rem" }}
              onClick={() => {
                handleFileUpload();
              }}
              disabled={uploadLoading}
            >
              {uploadLoading ? "Loading..." : "Submit Upload"}
            </Button>
          )}

          <Divider />

          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            pt={1.5}
            sx={{ fontSize: "1rem", fontWeight: 600 }}
          >
            Document Analyzer
          </Typography>
          <FormGroup sx={{ pb: 1 }}>
            {fileList &&
              fileList?.map((file: Record<string, any>, index: number) => {
                return (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={file.isSelected}
                        onChange={(event) =>
                          setFileList((prev: Record<string, any>[]) => {
                            prev[index].isSelected = event?.target?.checked;
                            return [...prev];
                          })
                        }
                      />
                    }
                    label={file.name.split("/")[1]}
                    sx={{ width: "100%" }}
                    componentsProps={{
                      typography: {
                        sx: { fontSize: "0.8rem" },
                      },
                    }}
                  />
                );
              })}
          </FormGroup>

          <Divider />

          <Button
            variant="text"
            color="primary"
            sx={{ p: 1, mt: 1.5, width: "unset" }}
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
        </>
      )}
    </>
  );
}
