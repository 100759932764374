import { decrypt } from "./encryption";

export const getFirstName = (name: string) => {
  return name.split(" ")[0];
};

export const handleLogin = (
  response: any,
  setUserDetails: any,
  source?: string,
  setSource?: any
) => {
  const encryptedData = response?.data?.data;
  localStorage.setItem("userDetails", encryptedData);
  source && localStorage.setItem("source", source);
  setUserDetails({ ...JSON.parse(decrypt(encryptedData)) });
  setSource(source);
};

export const capitalize = (str: string) => {
  if (!str) return "";
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const roundOff = (num: number, roundOffDigit: number = 2) => {
  return typeof num == "number" && !Number.isInteger(num)
    ? Number(num.toFixed(2))
    : num;
};

export const sanitizeUserInputQuery = (input: string) => {
  return input
    .replace(/db\.\w+\.\w+\(.*?\)/gi, "")
    .replace(/db\.\w+\(\)/gi, "")
    .replace(
      /(\{\s*)?\$\w+\s*:\s*(\{[^}]*\}|\d+|".*?"|'.*?'|`.*?`|‘.*?‘)(\s*\})?/gi,
      ""
    );
};

export const validateEmail = (email: string) => {
  const reg: RegExp =
    /^([A-Za-z0-9_\-\\.])+@([A-Za-z0-9_\-\\.])+\.([A-Za-z]{2,4})$/;
  return reg.test(email);
};
