import { useMemo } from "react";
import { useMediaQuery } from "@mui/material";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from "recharts";
import { capitalize, roundOff } from "../../utils/common";
import { COLORS } from "../../utils/constant";
import theme from "../../theme";

export default function Piechart({ data }: any) {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const dataKeys = Object.keys(data[0]);

  const formattedData = useMemo(() => {
    return data.map((item: any, index: number) => {
      let valueKey = Object.keys(item).find(
        (key) =>
          typeof item[key] === "number" && !["month", "year"].includes(key)
      );

      let labelKey = "";
      if (dataKeys.includes("month") && dataKeys.includes("year")) {
        labelKey = `${capitalize(item?.month?.slice(0, 3))} ${item?.year
          ?.toString()
          .slice(-2)}`;
        item[labelKey] = labelKey;
        delete item.year;
        delete item.month;
      } else {
        labelKey = Object.keys(item).find(
          (key) => typeof item[key] === "string"
        ) as string;
      }

      return {
        id: index,
        value: roundOff(item?.[valueKey!]),
        label: capitalize(item?.[labelKey!]),
      };
    });
  }, [data, dataKeys]);

  if (dataKeys.length > 3) {
    return null;
  }

  return (
    <ResponsiveContainer minHeight={350} maxHeight={700}>
      <PieChart>
        <Pie
          data={formattedData}
          dataKey="value"
          nameKey="label"
          cx="50%"
          cy="50%"
          outerRadius={isSmallScreen ? 90 : 110}
          style={{ paddingBottom: "1.25rem" }}
        >
          {formattedData.map((item: any, index: number) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip formatter={(value) => roundOff(value as number)} />
        {formattedData.length < (isSmallScreen ? 8 : 13) && (
          <Legend
            layout={isSmallScreen ? "horizontal" : "vertical"}
            align={isSmallScreen ? "center" : "right"}
            verticalAlign={isSmallScreen ? "bottom" : "middle"}
          />
        )}
      </PieChart>
    </ResponsiveContainer>
  );
}
