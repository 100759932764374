export function detectOS() {
  const isWindows = navigator.platform.indexOf("Win") > -1;
  const isMac = navigator.platform.indexOf("Mac") > -1;

  if (isWindows) {
    return "windows";
  }
  if (isMac) {
    return "mac";
  }
  return "default";
}
