import {
  DownloadRounded,
  Person,
  ThumbDownAlt,
  ThumbDownOffAlt,
  ThumbUpAlt,
  ThumbUpOffAlt,
  VolumeUpRounded,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { createRef, useEffect, useMemo, useState } from "react";
import Markdown from "react-markdown";
import { IConversation } from "../types/chatbot";
import Graphs from "./graphs";
import StarIcon from "./star_icon";
import { Link } from "react-router-dom";
import remarkGfm from "remark-gfm";
import DebugModal from "./debug_modal";
import { useAuth } from "../provider/auth_provider";

export default function Conversation({
  conversation,
  like,
  dislike,
  tool,
}: {
  conversation: IConversation;
  like: () => void;
  dislike: () => void;
  tool: string;
}) {
  const [open, setOpen] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const ref = createRef();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { source } = useAuth();

  const urlArray = useMemo(() => {
    return conversation?.url?.split(",").map((url) => url.trim()) || null;
  }, [conversation?.url]);

  function speak(message: string) {
    if (window.speechSynthesis.speaking) {
      window.speechSynthesis.cancel();
    } else {
      var speech = new SpeechSynthesisUtterance();
      speech.text = message;
      window.speechSynthesis.speak(speech);
    }
  }

  useEffect(() => {
    if (clickCount > 0) {
      const timeout = setTimeout(() => {
        setClickCount(0);
      }, 10000);

      // Clear timeout if user clicks again before the timeout
      return () => clearTimeout(timeout);
    }
  }, [clickCount]);

  const handleModal = () => {
    setOpen(!open);
  };

  const handleClick = () => {
    if (tool === "AMA @ Company" && !conversation.isLoading) {
      setClickCount((prevCount) => {
        const newCount = prevCount + 1;
        if (newCount >= 5) {
          handleModal();
          return 0;
        }

        return newCount;
      });
    }
  };

  function download() {
    try {
      (window as any)
        .html2pdf()
        .set({ filename: `conversation.pdf` })
        .from(ref.current)
        .save();
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <Box mb={isSmallScreen ? 2 : 4} ref={ref} p={isSmallScreen ? 1 : 0}>
      <Box px={isSmallScreen ? 0 : 2} marginBottom={0.5}>
        <List disablePadding>
          <ListItem>
            <ListItemAvatar sx={{ minWidth: 48 }}>
              <Avatar sx={{ width: 32, height: 32 }}>
                <Person />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={conversation.query}
              primaryTypographyProps={
                isSmallScreen
                  ? { fontSize: 13, fontWeight: 500 }
                  : { fontSize: 15, fontWeight: 500 }
              }
            ></ListItemText>
          </ListItem>
        </List>
      </Box>

      <Box
        ml={isSmallScreen ? 1 : 2.5}
        pt={isSmallScreen ? 0 : 1}
        width={isSmallScreen ? "auto" : "calc(100% - 1.25rem)"}
        display={"flex"}
        alignItems={"flex-start"}
        borderRadius={2}
        bgcolor={"#fff"}
        py={isSmallScreen ? 1.6 : 2}
        px={isSmallScreen ? 1 : 2}
        pl={isSmallScreen ? 0 : 1.4}
      >
        <Box>
          <Button onClick={handleClick}>
            <StarIcon isLoading={conversation.isLoading} />
          </Button>
        </Box>
        <Box
          flex={1}
          pr={isSmallScreen ? 0 : 1}
          pl={isSmallScreen ? 0 : 1}
          sx={{ maxWidth: "90%" }}
        >
          {conversation.isLoading ? (
            <Box pl={1} sx={{ marginTop: "-0.125rem" }}>
              <Skeleton height={32} width={"100%"} />
            </Box>
          ) : (
            <Box
              sx={
                isSmallScreen
                  ? { fontSize: 14, padding: 0 }
                  : { maxWidth: "100%", overflow: "auto", fontSize: 15 }
              }
              // dangerouslySetInnerHTML={{
              //   __html: (conversation.result || "").replace(/\n/ig,"<br />"),
              // }}
            >
              <Markdown
                remarkPlugins={[remarkGfm]}
                components={{
                  a: ({ node, ...props }) => (
                    <a {...props} target="_blank" rel="noopener noreferrer">
                      {props.children}
                    </a>
                  ),
                }}
              >
                {conversation.result ||
                  "Sorry! Can you please rephrase your query?"}
              </Markdown>

              {urlArray &&
                urlArray.map(
                  (url, index) =>
                    url && (
                      <Typography sx={{ mt: 1, fontSize: 13 }} key={index}>
                        <Link to={url} target="_blank">
                          {url}
                        </Link>
                      </Typography>
                    )
                )}

              {!conversation.isLoading &&
                conversation.comparison &&
                conversation.comparison.length > 0 && (
                  <Graphs type="compare table" data={conversation.comparison} />
                )}

              {!conversation.isLoading && conversation.isPlottable && (
                <Graphs
                  type={conversation.displyType}
                  data={conversation.message}
                />
              )}
            </Box>
          )}

          {!conversation.isLoading && (
            <Box pt={2} display={"flex"} justifyItems={"flex-start"}>
              <IconButton
                onClick={() => speak(conversation.result!)}
                sx={{ width: 32, height: 32 }}
              >
                <VolumeUpRounded sx={{ fontSize: 18 }} />
              </IconButton>
              {!source && (
                <>
                  <IconButton onClick={like} sx={{ width: 32, height: 32 }}>
                    {conversation.isLiked === true ? (
                      <ThumbUpAlt sx={{ fontSize: 18 }} />
                    ) : (
                      <ThumbUpOffAlt sx={{ fontSize: 18 }} />
                    )}
                  </IconButton>
                  <IconButton onClick={dislike} sx={{ width: 32, height: 32 }}>
                    {conversation.isLiked === false ? (
                      <ThumbDownAlt sx={{ fontSize: 18 }} />
                    ) : (
                      <ThumbDownOffAlt sx={{ fontSize: 18 }} />
                    )}
                  </IconButton>
                </>
              )}
              <IconButton onClick={download} sx={{ width: 32, height: 32 }}>
                <DownloadRounded sx={{ fontSize: 18 }} />
              </IconButton>
            </Box>
          )}
        </Box>
      </Box>
      <DebugModal
        open={open}
        handleModal={handleModal}
        conversation={conversation}
      />
    </Box>
  );
}
